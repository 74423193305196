<template>
  <div class="">
    <span class="loadingIcon"><font-awesome-icon icon="spinner" /></span>
    <p>サインインしています...</p>
  </div>
</template>

<script>
// import firebase from 'firebase/app'

export default {
  created () {
  },
  computed: {
  },
  mounted () {
    // firebase.auth().onAuthStateChanged((user) => {
    //  if (user) {
    //    console.log('login!!');
    //    this.$router.push('/mypage')
    //  }else{
    //    this.$router.push('/signin')
    //  }
    //  this.loading = false
    //})
  },
  methods: {
  }
}
</script>

<style scoped>
section{
  background: green;
}

.loadingIcon{
  display: inline-block;
  font-size: 4rem;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
  }
  to {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}
</style>
